import Layout from "../components/layout";
import SEO from "../components/seo";
import React, {useEffect, useState} from "react";
import {processPay} from "../services/pymes-service";
import {loadStripe} from '@stripe/stripe-js';
import { environment } from "../environments/environment";

const PayPage = () => {
  const [message, setMessage] = useState('Procesando pago...')
  const token = typeof document !== 'undefined' ? (new URL(document.location.href)).searchParams.get('token') : null;


  const setFinalMessage = (status) => {
    if (status === 400) {
      setMessage('No pudimos procesar tu pago. Por favor comunícate con nosotros')
    }
    if (status === 204) {
      setMessage('Tu pago ya había sido procesado previamente')
    }
    if(status === 200) {
      setMessage('Hemos procesado tu pago correctamente. En los próximos días recibirás un correo electrónico informándote del lanzamiento de tu campaña, y un informe diario para que puedas ver su seguimiento. Cualquier duda que tengas estamos a tu disposición en pymes@eldiario.es ¡Gracias por tu confianza!')
    }
    if (status >= 500) {
      setMessage('Ha ocurrido algún error en el servidor. por favor vuelve a intentarlo mas tarde.')
    }
  }

  useEffect(async () => {
    if (!token) {
      document.location.href = '/';
    }
    
    try {
      let stripe;
      if(!stripe) {
        stripe = await loadStripe(environment.stripeApiKey);
      }
      let status;   
      processPay(token)
        .then(res => {
          status = res.status;
          return res.json()
        })
        .then(async function (response) {
          if (response.client_secret) {
            stripe.confirmCardPayment(response.client_secret).then(res => {
              setMessage('Hemos procesado tu pago correctamente. En los próximos días recibirás un correo electrónico informándote del lanzamiento de tu campaña, y un informe diario para que puedas ver su seguimiento. Cualquier duda que tengas estamos a tu disposición en pymes@eldiario.es ¡Gracias por tu confianza!')
            }).catch(err => console.log(err));
            return;
          }

          setFinalMessage(status)
        })
    } catch(e) {
      setFinalMessage(500);
    }
  }, [token]);
  return (
    <Layout>
      <SEO title="Procesar pago"/>
      <article>
        <p>{message} {message.startsWith('No') ? <a target="_blank"
                                                    rel="noreferrer"
                                                    href={encodeURI(`mailto:pymes@eldiario.es?subject=Error al procesar mi pago&body=\n\nDespués de abrir el correo de confirmación veo que no se ha podido procesar mi pago.\n¿Podeís revisar qué ha pasado?\n\n--- no borrar ---\n\n${token}`)}>pymes@eldiario.es</a> : ''}</p>
      </article>
    </Layout>
  );
}

export default PayPage
